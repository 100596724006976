<template>
  <div class="set_content">
    <div class="wd_change">
      <div class="change_item">
        <div class="ci_left">
          <span>当前账号</span>
        </div>
        <div class="ci_right">
          <span>{{phone}}</span>
          <!-- <input v-model="form.cardempname"
                           type="text"
                           placeholder="请输入验证码" /> -->
        </div>
      </div>
      <div class="change_item">
        <div class="ci_left">
          <span>旧密码</span>
          <span class="right"
                @click="ishow"></span>
        </div>
        <div class="ci_right">
          <input v-model="form.oldpwd"
                 :type="showOldPwd ?'text': 'password'"
                 placeholder="请输入原始密码" />
          <md-icon :name="showOldPwd ?'visible': 'invisible'"
                   size="lg"
                   class="seePwd"
                   @click="showPwd"></md-icon>
        </div>
      </div>
      <div class="change_item solidTop">
        <div class="ci_left">
          <span>设置新密码</span>
        </div>
        <div class="ci_right">
          <input v-model="form.pwd"
                 :type="showNewPwd ?'text': 'password'"
                 placeholder="请设置新的登录密码" />
          <md-icon size="lg"
                   :name="showNewPwd ?'visible': 'invisible'"
                   class="seePwd"
                   @click="showNPwd"></md-icon>
        </div>
      </div>
      <div class="change_item">
        <div class="ci_left">
          <span>确认新密码</span>
        </div>
        <div class="ci_right">
          <input v-model="form.pwds"
                 :type="showNewPwd2 ?'text': 'password'"
                 placeholder="请再次输入密码" />
          <md-icon :name="showNewPwd2 ?'visible': 'invisible'"
                   size="lg"
                   class="seePwd"
                   @click="showNPwd2"></md-icon>
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="quitBtn" @click.stop="submit">确定</div>
    </div>
  </div>
</template>
<script>
import { upEmpnoPwd, quitlogin } from '@/api/basic/index'
import { Toast, Dialog } from 'mand-mobile'
export default {
  components: {
    [Dialog.name]: Dialog
  },
  data () {
    return {
      phone: '',
      form: {
        oldpwd: '', // 旧密码
        pwd: '', // 新密码
        pwds: '' // 确认密码
      },
      showOldPwd: false,
      showNewPwd: false,
      showNewPwd2: false
    }
  },
  created () {
    this.phone = this.$route.query.phone
  },
  methods: {
    showPwd (val) {
      this.showOldPwd = !this.showOldPwd
    },
    showNPwd (val) {
      this.showNewPwd = !this.showNewPwd
    },
    showNPwd2 (val) {
      this.showNewPwd2 = !this.showNewPwd2
    },
    submit () {
      if (this.form.pwd.length - 6 < 0) {
        Toast.info('请至少输入6位密码')
        return
      }
      upEmpnoPwd(this.form).then(res => {
        Toast.succeed('保存成功')
        quitlogin().then(() => {
          Toast.succeed('退出成功')
          sessionStorage.clear()
          localStorage.clear()
          this.$router.push({ path: '/login' })
        })
      })
    },
    ishow () {
      Dialog.alert({
        title: '提示',
        content: '首次更改密码，原密码为证件号后六位',
        cancelText: '取消',
        confirmText: '确定',
        onConfirm: () => {

        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.set_content {
  background-color: #F6F6F6;

  .wd_change {
    // border-top 50px solid #F6F6F6
    background-color: #FFFEFF;

    // padding-bottom 150px
    .change_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #F6F6F6;
      padding: 50px 60px;

      .ci_left {
        span {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }
      }

      .ci_right {
        span {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }

        input {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
          overflow: hidden;
          border: none;
          text-align: right;
          outline: none;
        }

        .seePwd {
          padding-left: 20px;
        }
      }
    }
  }

  .btn {
    text-align: center;
    padding-top: 200px;

    .quitBtn {
      padding: 30px 30px;
      margin: 80px auto;
      width: 90%;
      background-color: color-primary;
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      border-radius: 100px;
    }
  }
}

.solidTop {
  border-top: 50px solid #F6F6F6;
}

.right {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-image: url('~@/assets/image/help.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  left: 0.1rem;
}
</style>
